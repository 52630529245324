import * as React from "react";
import ManualLayout from "../../components/pages/manual/ManualLayout";
import ManualBody from "../../components/pages/manual/ManualBody";
import Seo from "../../components/seo";

const Undertake = () => {
  return (<>
    <ManualLayout selected='undertake'>
      <ManualBody
        title='我接任务'
      />
    </ManualLayout>
  </>)
}

export default Undertake;

export const Head = () => <Seo />
